import React, { useEffect, useState } from 'react'
import Base from '../layouts/base'
import Hero from '../components/hero'
import qs from 'qs'
import Dialog from '@material-ui/core/Dialog'
import { DialogContentText } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { navigate } from 'gatsby'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import { sendUnsubscribeRequest, sendVerifyRequest } from '../api/mailing_list'
import MailingListHeroImg from '../media/mailing_list_hero.jpeg'

const MailingList = () => {
  // Create state to track display of the different modals
  const [showVerifySuccess, setShowVerifySuccess] = useState(false)
  const [showUnsubscribeSuccess, setShowUnsubscribeSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  // The mailing_list page should only be accessed indirectly (e.g., via a link send in an email)
  // This page component leverages useEffect to parse the request parameters and pass them to a handler
  useEffect(() => {
    // Parse the query parameters
    const opts = qs.parse(window.location.search, { parseArrays: false, ignoreQueryPrefix: true })

    // Handle a verification request (v)
    if (opts['a'] === 'v') {
      // Check that fields exist and are of expected length
      if (!checkID(opts['s'], 32, 32) || !checkID(opts['v'], 32, 32)) {
        setShowError(true)
        return
      }

      sendVerifyRequest(opts['v'], opts['s']).then(
        (response) => {
          if (response['errors']) {
            setShowError(true)
          } else if (response['data']) {
            setShowError(false)
            setShowVerifySuccess(true)
          }
        },
        () => {
          setShowError(true)
        }
      )
      return
    }

    // Handle an unsubscribe request (u)
    if (opts['a'] === 'u') {
      // Check that fields exist and are of expected length
      if (!checkID(opts['s'], 32, 32)) {
        setShowError(true)
        return
      }

      // Check the campaign ID (if one was provided)
      if (opts['c']) {
        if (!checkID(opts['c'], 8, 64)) {
          setShowError(true)
          return
        }
      }

      // Send the request
      sendUnsubscribeRequest(opts['s'], opts['c'])
        .then(
          (response) => {
            if (response['errors']) {
              setShowError(true)
            } else if (response['data']) {
              setShowError(false)
              setShowUnsubscribeSuccess(true)
            }
          },
          () => {
            setShowError(true)
          }
        )
      return
    }

    // An unknown action was called
    setShowError(true)
  }, [])


  const onOk = () => {
    navigate('/resources')
  }

  return (
    <Base subject="Mailing List">
      <Hero
        tagline=""
        heroImage={MailingListHeroImg}
        heroHeight='120vh'
      />

      <Dialog open={showVerifySuccess}>
        <DialogTitle id="form-dialog-title">Verification Success!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Thank you! Your email has been verified and is now active.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showUnsubscribeSuccess}>
        <DialogTitle id="form-dialog-title">Unsubscribe Success.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            It's sad to see you go, but we understand the need to keep the inbox clean.
            You are welcome back at any time.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={showError}>
        <DialogTitle id="form-dialog-title">Uh oh!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Something went wrong. Please try again later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop open={true}>
        <CircularProgress color="inherit"/>
      </Backdrop>
    </Base>

  )
}

// checkID helps ensure (client side) that the provided parameters exist and are of an expected format
const checkID = (id, min, max) => {
  // The id should exist
  if (typeof (id) !== 'string') {
    return false
  }
  // The ID should be a 32-char hex string
  let re = new RegExp('[0-9a-fA-F]{' + min + ',' + max + '}')
  return id.match(re)
}


export default MailingList